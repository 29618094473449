import { ImageBlock, RichText } from '@sprinklr/shared-lib';
import React from 'react';
import { Box, ThemeProvider } from 'theme-ui';
import { StatData } from '../types';

const STAT_THEME = {
  styles: {
    h3: {
      fontSize: ['30px', '50px'],
      fontWeight: 700,
      marginBottom: 0,
      lineHeight: '145%',
    },
    p: {
      fontSize: ['12px', '16px'],
    },
  },
};

const STAT_IMAGE_STYLES = {
  width: ['32px', '60px'],
  height: ['32px', '60px'],
};

const Stat = ({ stat }: { stat: StatData }) => {
  return (
    <ThemeProvider theme={STAT_THEME}>
      <Box>
        <ImageBlock image={stat.media} imageSx={STAT_IMAGE_STYLES} />
        <RichText richTextObject={stat.text} />
      </Box>
    </ThemeProvider>
  );
};

export default Stat;
