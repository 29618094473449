import { ThemeUIStyleObject } from 'theme-ui';

export const TITLE_STYLES: ThemeUIStyleObject = {
  fontSize: '30px',
  fontWeight: '700',
  lineHeight: '32px',
  marginBlockEnd: '36px',
  paddingInline: '52px',
};
export const CONTAINER_STYLES: ThemeUIStyleObject = {
  height: '100%',
  alignItems: 'center',
  backgroundColor: 'white',
  position: 'relative',
  zIndex: 0,
};
export const RIGHT_COLUMN_BG_STYLES: ThemeUIStyleObject = {
  position: 'absolute',
  width: '50%',
  height: '100%',
  right: 0,
  backgroundColor: '#EDF9FF',
  zIndex: -1,
};
export const GRID_STYLES: ThemeUIStyleObject = {
  gridTemplateRows: 'auto 1fr',
  gridTemplateColumns: '1fr 1fr',
  placeItems: 'center start',
};
export const STATEMENT_CONTAINER_STYLES: ThemeUIStyleObject = {
  flexDirection: 'column',
  gap: '28px',
  paddingInline: [0, '52px'],
};
export const STATS_CONTAINER_STYLES: ThemeUIStyleObject = {
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingInline: [0, '52px'],
};

export const DESKTOP_LAYOUT_CONTAINER_STYLES: ThemeUIStyleObject = {
  display: ['none', null, 'block'],
  height: '100%',
};
export const MOBILE_LAYOUT_CONTAINER_STYLES: ThemeUIStyleObject = {
  display: ['block', null, 'none'],
  height: '100%',
};
export const MOBILE_CONTAINER: ThemeUIStyleObject = {
  height: '100%',
  flexDirection: 'column',
  paddingBlock: '32px',
};

export const MOBILE_STATEMENTS_LIST_CONTAINER_STYLES: ThemeUIStyleObject = {
  flex: 1,
  flexDirection: 'column',
  backgroundColor: 'white',
  justifyContent: 'center',
  paddingInline: '24px',
};
export const MOBILE_TITLE_STYLES: ThemeUIStyleObject = {
  fontSize: '30px',
  fontWeight: '700',
  lineHeight: '32px',
  marginBlockEnd: '36px',
};
export const MOBILE_STATS_LIST_CONTAINER_STYLES: ThemeUIStyleObject = {
  flex: 1,
  flexDirection: 'column',
  backgroundColor: '#EDF9FF',
  justifyContent: 'center',
  paddingInline: '24px',
};
