import React from 'react';
import { Box, Flex, Grid } from 'theme-ui';
import {
  CONTAINER_STYLES,
  RIGHT_COLUMN_BG_STYLES,
  GRID_STYLES,
  TITLE_STYLES,
  STATEMENT_CONTAINER_STYLES,
  STATS_CONTAINER_STYLES,
  DESKTOP_LAYOUT_CONTAINER_STYLES,
  MOBILE_LAYOUT_CONTAINER_STYLES,
  MOBILE_CONTAINER,
  MOBILE_STATEMENTS_LIST_CONTAINER_STYLES,
  MOBILE_TITLE_STYLES,
  MOBILE_STATS_LIST_CONTAINER_STYLES,
} from './styles';
import { Props } from './types';
import StatementBox from './components/StatementBox';
import Stat from './components/Stat';

const DesktopLayout = ({ title, statsTitle, statements, stats }: Props) => {
  return (
    <Box sx={DESKTOP_LAYOUT_CONTAINER_STYLES}>
      <Flex sx={CONTAINER_STYLES}>
        <Box sx={RIGHT_COLUMN_BG_STYLES}></Box>
        <Grid sx={GRID_STYLES}>
          <Box sx={TITLE_STYLES}>{title}</Box>
          <Box sx={TITLE_STYLES}>{statsTitle}</Box>
          <Flex sx={STATEMENT_CONTAINER_STYLES}>
            {statements.map(statement => {
              return <StatementBox key={statement} statement={statement} />;
            })}
          </Flex>
          <Flex sx={STATS_CONTAINER_STYLES}>
            {stats.map(stat => {
              return <Stat key={stat.title} stat={stat} />;
            })}
          </Flex>
        </Grid>
      </Flex>
    </Box>
  );
};

const MobileLayout = ({ title, statsTitle, statements, stats }: Props) => {
  return (
    <Box sx={MOBILE_LAYOUT_CONTAINER_STYLES}>
      <Flex sx={MOBILE_CONTAINER}>
        <Flex sx={MOBILE_STATEMENTS_LIST_CONTAINER_STYLES}>
          <Box sx={MOBILE_TITLE_STYLES}>{title}</Box>
          <Flex sx={STATEMENT_CONTAINER_STYLES}>
            {statements.map(statement => {
              return <StatementBox key={statement} statement={statement} />;
            })}
          </Flex>
        </Flex>
        <Flex sx={MOBILE_STATS_LIST_CONTAINER_STYLES}>
          <Box sx={MOBILE_TITLE_STYLES}>{statsTitle}</Box>
          <Flex sx={STATS_CONTAINER_STYLES}>
            {stats.map(stat => {
              return <Stat key={stat.title} stat={stat} />;
            })}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

const ListStatsShowcase = (props: Props) => {
  return (
    <React.Fragment>
      <DesktopLayout {...props} />
      <MobileLayout {...props} />
    </React.Fragment>
  );
};

export default ListStatsShowcase;
