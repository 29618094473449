import React from 'react';
import { Box } from 'theme-ui';

const STATEMENT_STYLES = {
  width: '100%',
  fontSize: ['16px', '22px'],
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: ['24px', '32px'],
  padding: '12px 24px',
  backgroundColor: 'rgba(217, 217, 217, 0.30)',
  borderRadius: '6px',
};

const StatementBox = ({ statement }: { statement: string }) => {
  return <Box sx={STATEMENT_STYLES}>{statement}</Box>;
};

export default StatementBox;
